







































































/deep/ .card {
  background-color: unset;

  .form-control {
    background-color: #100F32;
    border-color: #3C4567;
    color: #fff;
  }
}
